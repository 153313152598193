import React from 'react';
import AppHeader from '../../components/header/AppHeader';
import bgLogo from '../../assets/home/homepage-background.svg';

const Features = [
    {
        title: "Secure",
        description: "Neotrust uses blockchain to create an unbreakable link between you and your intellectual property. This technology uses cryptography, decentralization, and immutability to store validated IP ownership records in a transparent and secure way.",
        classes: 'w-full p-8 border-b md:w-1/2 md:border-r lg:w-1/3 border-bluegray',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.75} stroke="currentColor" className="w-8 h-8 text-ntpurple">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
        </svg>

    },
    {
        title: "Immutable",
        description: "Immutable objects are objects that cannot be changed after they are created. They can be used to verify the authenticity of documents, to track the ownership of assets, and to ensure the integrity of data. Neotrust uses this foundation of certainty in a world that is constantly changing.",
        classes: 'w-full p-8 border-b md:w-1/2 lg:w-1/3 lg:border-r border-bluegray',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.75} stroke="currentColor" className="w-8 h-8 text-ntpurple">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
        </svg>

    },
    {
        title: "Verified",
        description: "You can check the accuracy of the item by an immutable blockchain system or ledger. These systems are reliable sources and is consistent with other evidence if there is a claim against ownership in a legal case.",
        classes: 'w-full p-8 border-b md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0 border-bluegray',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.75} stroke="currentColor" className="w-8 h-8 text-ntpurple">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
        </svg>

    },
    {
        title: "Certified",
        description: "A blockchain-based digital certificate can be used to verify ownership of intellectual property (IP). This is more secure than traditional methods, such as paper certificates, as the blockchain is a tamper-proof database. The certificate can enhance security, increase inherent value, and reduce fraud.",
        classes: 'w-full p-8 border-b md:w-1/2 lg:w-1/3 lg:border-r lg:border-b-0 border-bluegray',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.75} stroke="currentColor" className="w-8 h-8 text-ntpurple">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
        </svg>

    },
    {
        title: "Authenticated",
        description: "When an ID is fully authenticated and combined on-chain with your time stamped IP, you can be more confident that the current owner of an asset is the rightful owner. This is because the ID has been verified by a trusted third party, such as a government agency or a blockchain network.",
        classes: 'w-full p-8 border-b md:w-1/2 md:border-r md:border-b-0 lg:w-1/3 lg:border-b-0 border-bluegray',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.75} stroke="currentColor" className="w-8 h-8 text-ntpurple">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
        </svg>

    },
    {
        title: "Versatile",
        description: "Neotrust's NFX is designed for your unique needs. Whether protecting personal creations or business documents, NFX handles diverse digital content sizes and types. It offers a flexible solution for securing and managing digital assets.",
        classes: 'w-full p-8 md:w-1/2 lg:w-1/3 border-bluegray',
        icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.75} stroke="currentColor" className="w-8 h-8 text-ntpurple">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
        </svg>


    }
];

const FeaturesComponent: React.FC = () => {
    return (
        // <div className="bg-offwhite" id='features'>
        <div className="w-full mx-0 min-h-screen bg-center bg-no-repeat bg-cover flex flex-col" style={{ backgroundImage: `url(${bgLogo})` }}>
            <AppHeader classNameProps="bg-opacity-50" />
            <div className="container p-6 px-6 mx-auto mb-6">
                <div className="sm:px-6 text-start">
                    <h2 className="text-4xl font-bold tracking-wide text-[#050426]">
                        Features
                    </h2>
                </div>
                <div className="flex flex-wrap my-8 text-white bg-bluegray rounded-3xl">
                    {Features.map((feature, index) => (
                        <div key={index} className={`${feature.classes}`}>
                            <div className="flex items-center text-center justify-center mb-6">
                                <span className='inline-flex text-center items-center px-6 py-2 bg-[#d9d2ff] rounded-full text-ntpurple font-semibold'>
                                    {feature.icon}
                                    <div className="ml-4 text-xl">
                                        {feature.title}
                                    </div>
                                </span>
                            </div>
                            <p className="leading-loose text-gray-200 text-md text-center">
                                {feature.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FeaturesComponent;
