import React from 'react';
import GetStartedComponentNew from './GetStartedComponentNew';
import WhyNeotrustSection from './WhyNeotrust';
import TheProblemSection from './TheProblem';
import TableSection from './NeotrustVsOthers';
import TeamComponent from './TeamComponent';

const HomePage: React.FC = () => {
  return (
    <div className="flex flex-col flex-grow overflow-y-auto bg-offwhite">
        <GetStartedComponentNew />
        <TheProblemSection />
        <WhyNeotrustSection />
        <TableSection />
        {/* <TeamComponent /> */}
    </div>
  );
}

export default HomePage;
