import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, ChartOptions, Plugin } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import API from "../../apiClient/NeoTrustAPI";
import { useAuth0 } from "@auth0/auth0-react";
import { FeatureUsage, TransactionTypeKeys, UserUtilizationAPIResponse } from "../../utils/types";
import { useEffect, useState } from "react";
import timestampIcon from '../../assets/icons/History-white.svg';
import verifyIcon from '../../assets/icons/Verified-white.svg';
import dashboardIcon from '../../assets/icons/Dashboard.svg';
import { useNavigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);


export const getChartData = (dataArr: number[]) => {
	return {
		labels: ['Timestamps Available', 'Timestamps Used'],
		datasets: [
			{
				label: 'Count',
				data: dataArr,
				backgroundColor: [
					'#8965ea',
					'#2CC6AC',
				],
				borderColor: [
					'#8965ea',
					'#2CC6AC',
				],
			},
		],
	}
}

const options: ChartOptions<'doughnut'> = {
	plugins: {
		tooltip: {
			titleFont: {
				size: 16
			},
			bodyFont: {
				size: 16
			},
		},
		legend: {
			display: true,
			position: "bottom",
			align: "center",
			labels: {
				// boxWidth: 36,
				// padding: 40,
				color: '#fff',
				font: {
					size: 14,
				},
			},
		},
	}
}

const UsageCharts: React.FC = () => {
	const navigate = useNavigate();
	const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance
	// const [loader, setLoader] = useState<STATUS>(STATUS.NONE);
	const [timestampUsage, setTimestampUsage] = useState<FeatureUsage>();
	const [timestampStoreUsage, setTimestampStoreUsage] = useState<FeatureUsage>();
	const [timestampChartData, setTimestampChartData] = useState<any>(getChartData([]));
	const [timestampStoreChartData, setTimestampStoreChartData] = useState<any>(getChartData([]));

	const getUserUtilization = async () => {
		try {
			const response = await api.get('/user/utilization');
			const data: UserUtilizationAPIResponse = response.data;
			// console.log('User Utilization:', data);
			if (data.data.length) {
				const allFeaturesUsage = data.data;
				allFeaturesUsage.forEach((value, i) => {
					if (value.featureName === TransactionTypeKeys.timestamp) {
						setTimestampUsage(value);
					}
					if (value.featureName === TransactionTypeKeys.timestamp_and_store) {
						setTimestampStoreUsage(value);
					}
				});
			}
			// setLoader(STATUS.SUCCESS);
		} catch (e: any) {
			console.error('Error fetching user utilization:', e);
			// showToastUtil({ status: TOAST_TYPE.ERROR, message: e?.message || 'Error fetching user info.' });
			// setLoader(STATUS.ERROR);
		}
	};

	useEffect(() => {
		getUserUtilization();
	}, []);

	useEffect(() => {
		const used = timestampUsage?.currentUsage ?? 0;
		const available = (timestampUsage?.maxQuota ?? 0) - used;
		const dataArr = [available, used];
		setTimestampChartData(getChartData(dataArr));
	}, [timestampUsage]);

	useEffect(() => {
		const used = timestampStoreUsage?.currentUsage ?? 0;
		const available = (timestampStoreUsage?.maxQuota ?? 0) - used;
		const dataArr = [available, used];
		setTimestampStoreChartData(getChartData(dataArr));
	}, [timestampStoreUsage]);


	return (<>
		<div className="mx-6 relative border-none flex flex-row flex-wrap items-center justify-around rounded-3xl bg-bluegray grid-cols-12">
			<div className="flex flex-col justify-between self-start h-full col-span-4 text-white ml-4">
				<div className="flex text-3xl font-bold pt-6 pb-12 items-center">
					<img className="w-16 h-auto -my-3" src={dashboardIcon} alt="" />
					<span className='ml-3'>{'Your Data'}</span>
				</div>
				<div className="flex flex-col gap-8 text-[#ffffff] font-bold">
					<div className='inline-flex text-center items-center pl-6 pr-10 py-2 bg-ntpurple rounded-full cursor-pointer hover:bg-[#2CC6AC]'
						onClick={() => navigate('/stamp')}
					>
						<img className="w-16 h-auto -my-1" src={timestampIcon} alt="" />
						<div className="text-2xl text-center mx-auto">
							{'Timestamp'}
						</div>
					</div>
					<div className='inline-flex text-center items-center pl-6 pr-10 py-2 bg-ntpurple rounded-full cursor-pointer hover:bg-[#2CC6AC]'
						onClick={() => navigate('/verify')}
					>
						<img className="w-16 h-auto -my-1" src={verifyIcon} alt="" />
						<div className="text-2xl text-center mx-auto">
							{'Verify'}
						</div>
					</div>
				</div>
			</div>
			{
				// false && <>
				timestampStoreUsage && <>
					<div className="col-span-3 p-6 relative flex flex-col items-center justify-center">
						<h3 className="pb-4 font-semibold leading-6 text-center text-2xl text-white">Timestamps + Store</h3>
						<div className="w-[300px]">
							<Doughnut data={timestampStoreChartData} options={options} />
						</div>
					</div>
				</>
			}
			<div className="col-span-3 p-6 relative flex flex-col items-center justify-center">
				<h3 className="pb-4 font-semibold leading-6 text-center text-2xl text-white">Standard Timestamps</h3>
				<div className="w-[300px]">
					<Doughnut data={timestampChartData} options={options} />
				</div>
			</div>
		</div>
	</>);
}

export default UsageCharts;