import React from 'react';
import { useNavigate } from 'react-router-dom';

const TheProblemSection: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="w-full mx-0 p-8 md:p-20 bg-[#8965e9]">
            <div className="container flex flex-wrap items-cente max-w-7xl mx-auto">
                <div className="w-full">
                    <h2 className="text-3xl md:text-5xl font-bold mb-6 text-white">The Problem</h2>
                    <p className="mb-4 text-xl text-white">Digital content is susceptible to fraud, plagiarism, and piracy on a daily basis contaminating data and leading to severe brand-damaging events.</p>
                    <ul className="list-disc pl-5 ml-8 space-y-4 text-xl text-white">
                        <li>Data tampering</li>
                        <li>Revenue loss</li>
                        <li>Unauthorized use of IP</li>
                    </ul>
                    <button onClick={() => navigate('/solutions')} className="float-right text-xl mt-4 px-10 py-2 md:mr-10 text-white font-semibold border-2 border-white rounded-full transition duration-300 hover:bg-white hover:text-[#8965e9] focus:outline-none">
                        How we help
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TheProblemSection;
