import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect } from 'react';
import { UserInfoContext } from './UserInfoProvider';
import { UserStatus } from '../utils/userUtils';
import nextIcon from '../assets/icons/Next2.svg';
import { useLocation, useNavigate } from 'react-router-dom';


const AnnouncementBar: React.FC = () => {
    const [showBar, setShowBar] = useState<boolean>(false);
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const { userStatus } = React.useContext(UserInfoContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if ((location.pathname === '/') && (!isAuthenticated || (isAuthenticated && userStatus !== UserStatus.COMPLETE))) {
            setShowBar(true);
        } else {
            setShowBar(false);
        }
    }, [userStatus, isAuthenticated, location.pathname]);

    const onClick = () => {
        if (!isAuthenticated) {
            loginWithRedirect();
        } else if (isAuthenticated) {
            if (userStatus === UserStatus.NO_USER) {
                navigate('/onboarding');
            } else if (userStatus === UserStatus.EXPIRED) {
                navigate('/onboarding?step=plan');
            } else if (userStatus === UserStatus.KEY_READY) {
                navigate('/onboarding?step=plan');
            } else if (userStatus === UserStatus.COMPLETE) {
                return;
            }
        }
    }

    return (<>
        {showBar && <div className='h-[50px] w-full bg-[#B1A6FF] flex items-center justify-center'>
            {userStatus === UserStatus.NO_USER ?
                <>
                    <span className='text-center hidden sm:inline-flex px-2'><span className='font-bold pr-2'>Get Stamping!</span>Create your account today.</span>
                    <span className='text-center sm:hidden inline-flex px-2'>Get started today!</span>
                </>
                :
                <>
                    <span className='text-center hidden sm:inline-flex px-2'><span className='font-bold pr-2'>You’re almost there!</span>Finish setting up your account today.</span>
                    <span className='text-center sm:hidden inline-flex px-2'>Finish setting up your account!</span>
                </>
            }
            <img className="w-[32px] h-auto cursor-pointer" src={nextIcon} alt="" onClick={() => onClick()} />
        </div>}
    </>);
}

export default AnnouncementBar;

//You’re almost there! Finish setting up your account today.