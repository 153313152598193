import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import instagramLogo from '../../assets/icons/instagram.svg';
import facebookLogo from '../../assets/icons/facebook.svg';
import linkedinLogo from '../../assets/icons/linkedin.svg';
import xLogo from '../../assets/icons/x-logo.svg';
import mediumLogo from '../../assets/icons/medium-logo.png';
import logo from '../../assets/logo/neotrust_logo_outlines.svg';


const Footer = () => {
    const navigate = useNavigate();
    return(
        <div className="w-full mx-0 p-4 md:py-10 md:px-10 lg:px-20 bg-[#8965e9]">
            <div className="container flex flex-wrap items-center mx-auto">
                <div className="w-full flex flex-col md:flex-row justify-between items-center md:items-start">
                    <div className="flex flex-col space-y-4 mb-16 sm:mb-10 md:mb-0">
                        <img src={logo} alt="Logo" className="h-12 w-auto self-center"/>
                        
                        <button 
                            onClick={() => navigate('/contact')} 
                            className="text-xl mt-4 px-3 py-0.5 bg-white text-purple-500 font-semibold border-2 border-white rounded-full transition duration-300 focus:outline-none self-center">
                            Contact us
                        </button>
                        
                        <div className="flex space-x-8 justify-center p-2.5">
                            <a href="https://www.facebook.com/people/MyNeotrust/61556487141298/" target="_blank" rel="noreferrer">
                                <img src={facebookLogo} alt="Facebook" className="w-auto h-10" />
                            </a>
                            <a href="https://www.instagram.com/myneotrust/" target="_blank" rel="noreferrer">
                                <img src={instagramLogo} alt="Instagram" className="w-auto h-10" />
                            </a>
                            <a href="https://www.linkedin.com/company/myneotrust/" target="_blank" rel="noreferrer">
                                <img src={linkedinLogo} alt="LinkedIn" className="w-auto h-10" />
                            </a>
                            <a href="https://twitter.com/MyNeotrust/" target="_blank" rel="noreferrer">
                                <img src={xLogo} alt="X (Twitter)" className="w-auto h-10 py-1.5" />
                            </a>
                            <a href="https://medium.com/@MyNeotrust/" target="_blank" rel="noreferrer">
                                <img src={mediumLogo} alt="LinkedIn" className="w-auto h-10 -ml-2" />
                            </a>
                        </div>
                        
                        <div className="text-white text-base text-center font-light">
                            © 2024 Consentize, Inc. All rights reserved.
                        </div>
        
                        <div className="text-white text-base text-center font-light">
                            701 Ellicott Street, Buffalo, NY, 14203. <br/>
                            <a href="mailto:hello@consentize.com" className="text-white text-center font-light underline">
                                hello@consentize.com
                            </a>
                        </div>

                        
        
                        <div className="flex space-x-8 justify-center p-2.5">
                            <div>
                                <Link to='/' className="text-white font-bold hover:underline">
                                    Sitemap
                                </Link>
                            </div>
                            <div>
                                <Link to="/privacy-policy" className="text-white font-bold hover:underline">
                                    Privacy policy
                                </Link>
                            </div>
                            <div>
                                <Link to="/terms-and-conditions#" className="text-white font-bold hover:underline">
                                    Terms of service
                                </Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="flex flex-col space-y-4">
                        <div className="grid grid-cols-3 md:grid-cols-2 gap-x-16 gap-y-8">
                            <div className="text-center md:text-right text-lg">
                                <div className="text-white font-bold">
                                    Company
                                </div>
                                <div>
                                    <Link to="/" onClick={() => navigate('/#neotrust')}  className="text-white font-light hover:underline">
                                        About
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/" className="text-white font-light hover:underline">
                                        Press
                                    </Link>
                                </div>
                            </div>
                            <div className="text-center md:text-right text-lg">
                                <div className="text-white font-bold">
                                    Solutions
                                </div>
                                <div>
                                    <Link to='/stamp' className="text-white font-light hover:underline">
                                        Timestamp
                                    </Link>
                                </div>
                                <div>
                                    <Link to='/verify' className="text-white font-light hover:underline">
                                        Verify
                                    </Link>
                                </div>
                            </div>
                            <div className="text-center md:text-right text-lg md:col-span-2">
                                <div className="text-white font-bold">
                                    Resources
                                </div>
                                <div>
                                    <Link to='/faqs' className="text-white font-light hover:underline">
                                        FAQ
                                    </Link>
                                </div>
                                <div>
                                    <Link to="/contact" className="text-white font-light hover:underline">
                                        Help
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
};
export default Footer;