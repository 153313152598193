import React, { useEffect } from "react";
import { UserInfoAPIResponse, UserStatus, checkValidUserPlan } from "../utils/userUtils";
import API from "../apiClient/NeoTrustAPI";
import { useAuth0 } from "@auth0/auth0-react";

interface UserInfoContextType {
    userInfoData: UserInfoAPIResponse | null;
    userStatus: UserStatus;
    isPremium: boolean;
    refreshUserInfo: () => void;
}

export const UserInfoContext = React.createContext<UserInfoContextType>({
    userInfoData: null,
    userStatus: UserStatus.NO_USER,
    isPremium: false,
    refreshUserInfo: () => { }
});

interface UserProviderProps {
    children: React.ReactNode;
}

export const UserInfoProvider: React.FC<UserProviderProps> = ({ children }) => {
    const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance
    const { isAuthenticated, isLoading } = useAuth0();

    const [userInfoData, setData] = React.useState<UserInfoAPIResponse | null>(null);
    const [userStatus, setUserStatus] = React.useState<UserStatus>(UserStatus.NO_USER);
    const [isPremium, setIsPremium] = React.useState<boolean>(false);
    const [refreshToken, setRefreshToken] = React.useState<number>(0);

    const refreshUserInfo = () => {
        setRefreshToken(prevToken => prevToken + 1);
    };

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const response = await api.get('/user/info');
                if (response.status !== 200) throw new Error('Error fetching user info.');
                const fetchedData: UserInfoAPIResponse = response.data;
                setData(fetchedData);
                const { userStatus: fetchedUserStatus, isPremium: fetchedIsPremium = false } = checkValidUserPlan(fetchedData);
                setUserStatus(fetchedUserStatus);
                setIsPremium(fetchedIsPremium);
            } catch (e: any) {
                console.error('Error fetching user info:', e);
                // showToastUtil({ status: TOAST_TYPE.ERROR, message: e?.message || 'Error fetching user info.' });
            }
        };
        console.log('isLoading, isAuthenticated', isLoading, isAuthenticated);
        if (!isLoading) getUserInfo();
    }, [refreshToken, isAuthenticated, isLoading]);

    return (
        <UserInfoContext.Provider value={{ userInfoData, userStatus, isPremium, refreshUserInfo }}>
            {children}
        </UserInfoContext.Provider>
    );
};
