import React from 'react';
import logo from '../../assets/logo/neotrust_logo.svg';

const TableSection: React.FC = () => {
    return (
        <div className="w-full mx-0 p-4 md:p-20 bg-[#8965e9]">
            <div className="container flex flex-wrap items-center mx-auto">
                <div className="w-full">
                    <h2 className="text-2xl md:text-3xl lg:text-5xl font-bold mb-6 md:mb-10 text-white">Unrivaled security</h2>
                    <div className="overflow-x-auto">
                        <table className="w-full rounded-2xl">
                            <thead>
                                <tr>
                                    <th className="bg-lightpurple py-2 md:py-4 px-2 md:px-4 text-center text-xl md:text-2xl font-bold border border-black border-t-0 border-l-0 rounded-tl-3xl">Protections</th>
                                    <th className="bg-[#ffffff] py-2 md:py-4 px-2 md:px-4 text-center text-xl md:text-2xl font-bold border border-black border-t-0 border-l-0">
                                        <img className="w-24 md:w-36 h-auto mx-auto" src={logo} alt="Neotrust" />
                                    </th>
                                    <th className="bg-lightpurple py-2 md:py-4 px-2 md:px-4 text-center text-xl md:text-2xl font-bold border border-black border-t-0 border-l-0 border-r-0 rounded-tr-3xl">Others</th>
                                </tr>
                            </thead>
                            <tbody className='text-lg md:text-xl font-normal'>
                            <tr>
                                        <td className="bg-lightpurple w-1/3 py-2 px-4 text-center border border-black border-t-0 border-l-0">Register IP</td>
                                        <td className="bg-[#ffffff] w-1/3 py-2 px-4 text-center border border-black border-t-0 border-l-0">Takes a minute</td>
                                        <td className="bg-lightpurple w-1/3 py-2 px-4 text-center border border-black border-t-0 border-l-0 border-r-0">
                                            Expensive<br />
                                            Time-consuming
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-lightpurple w-1/3 py-2 px-4 text-center border border-black border-t-0 border-l-0">Anti-fraud techniques</td>
                                        <td className="bg-[#ffffff] w-1/3 py-2 px-4 text-center border border-black border-t-0 border-l-0">
                                            Certified and sealed with<br />
                                            timestamp precision.<br />
                                            Cannot be removed<br />
                                            or circumvented.
                                        </td>
                                        <td className="bg-lightpurple w-1/3 py-2 px-4 text-center border border-black border-t-0 border-l-0 border-r-0">
                                            Watermarks are unreliable.<br />
                                            Copyright notices can be<br />
                                            ignored or overlooked.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bg-lightpurple w-1/3 py-2 px-4 text-center border border-black border-t-0 border-l-0 border-b-0 rounded-bl-3xl">
                                            Contracts &<br />
                                            Legal Action
                                        </td>
                                        <td className="bg-[#ffffff] w-1/3 py-2 px-4 text-center border border-black border-t-0 border-l-0 border-b-0">
                                            Prevents fraud<br />
                                            Resolve disputes<br />
                                            Tracks asset life cycle<br />
                                            Total compliance</td>
                                        <td className="bg-lightpurple w-1/3 py-2 px-4 text-center border border-black border-t-0 border-l-0 border-r-0 border-b-0 rounded-br-3xl">
                                            Difficult to enforce<br />
                                            Expensive litigation<br />
                                            Time-consuming
                                        </td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TableSection;
