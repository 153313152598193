import React from 'react';
import bgLogo from '../../assets/home/homepage-background.svg';
import AppHeader from '../../components/header/AppHeader';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';


const LandingSection: React.FC = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const navigate = useNavigate();
    const onGetStartedClick = () => {
        if (isAuthenticated) {
            navigate('/stamp');
        } else {
            loginWithRedirect();
        }
    };
    return (
        <div className="w-full mx-0 h-screen bg-center bg-no-repeat bg-cover flex flex-col justify-between" style={{ backgroundImage: `url(${bgLogo})` }}>
            <AppHeader classNameProps="bg-opacity-50" />
            <div className="container p-8 flex flex-wrap items-center flex-grow max-w-7xl mx-auto">
                {/* Text & Buttons Column */}
                {/* <div className="md:w-3/5 w-full flex flex-col justify-center h-full"> */}
                <div className="w-[55%] flex flex-col justify-center h-full">
                    <h1 className="text-4xl sm:text-5xl font-bold mb-4 whitespace-pre-wrap">{'Ownership.  Authenticated.'}</h1>
                    <p className="mb-8 text-2xl sm:text-3xl">Safeguard your data and intellectual property with Neotrust.</p>
                    <div className="flex">
                        <button onClick={() => onGetStartedClick()} className="inline-block text-xl font-bold leading-6 py-3 px-8 bg-ntpurple hover:bg-ntpurple-600 hover:border-ntpurple-600 text-white border-ntpurple border-2 transition ease-in duration-200 text-center shadow-md focus:outline-none rounded-full hover:bg-white hover:text-[#8965e9]">Get Started</button>
                    </div>
                </div>
                {/* <div className="w-3/5 md:w-full flex flex-col justify-center h-full"></div> */}
            </div>
        </div>
    );
}

export default LandingSection;
