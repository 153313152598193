import { CheckIcon } from '@heroicons/react/20/solid'
import { EnvConfig } from '../../utils/envConfig';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import bgLogo from '../../assets/home/homepage-background.svg';
import AppHeader from '../../components/header/AppHeader';

const tiers = [
	{
		name: 'Creator',
		id: 'tier-creator',
		href: '#',
		priceMonthly: '$4.99',
		description: 'For individual users looking to prove ownership of their content.',
		features: [
			'50 stamps/month',
			'Basic analytics',
			'Perfect for individual users'
		],
		mostPopular: false,
		price_id: EnvConfig.STRIPE_CREATOR_ID ?? '',
	},
	{
		name: 'Creator +',
		id: 'tier-creator-plus',
		href: '#',
		priceMonthly: '$9.99',
		description: 'For larger users and small businesses with multiple works a day needing certification.',
		features: [
			'500 stamps/month',
			'Advanced analytics',
			'Ideal for professionals and small businesses',
			'File Mapping'
		],
		mostPopular: true,
		price_id: EnvConfig.STRIPE_CREATOR_PLUS_ID ?? '',
	},
	{
		name: 'Enterprise',
		id: 'tier-enterprise',
		href: '#',
		priceMonthly: 'TBD',
		description: 'Dedicated support and infrastructure for your company.',
		features: [
			'Unlimited stamps/month',
			'Advanced analytics',
			'Crafted for corporations and large-scale needs',
			'File Mapping'
		],
		mostPopular: false,
		price_id: ''
	},
]

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}

interface IProps {
	onNextStep?: () => void; // Callback for the next step
	handlePriceID?: (price_id: string) => void; // Callback for the next step
	isOnboardingPage: boolean;
}

const PlansComponent: React.FC<IProps> = ({ handlePriceID = () => { }, onNextStep = () => { }, isOnboardingPage }) => {
	const { loginWithRedirect } = useAuth0();
	const navigate = useNavigate();
	const handleSubmit = (tier_id: string, price_id: string) => {
		if (isOnboardingPage) {
			// console.log('price_id', price_id);
			// Validate the form and handle submission here
			handlePriceID(price_id);
			// If successful, call the callback to move to the next step
			onNextStep();
		} else {
			if (tier_id === 'tier-enterprise') {
				navigate('/contact');
			} else {
				loginWithRedirect();
			}
		}
	};

	return (
		<div className={classNames(
			isOnboardingPage ? 'bg-white pt-6' : 'w-full mx-0 min-h-screen bg-center bg-no-repeat bg-cover flex flex-col',
			'pb-24',
		)}
			style={{
				backgroundImage: isOnboardingPage ? 'none' : `url(${bgLogo})`,
			}}
		>
			{!isOnboardingPage && <AppHeader classNameProps="bg-opacity-50" />}
			<div className={classNames(
				isOnboardingPage ? '' : '',
				'mx-auto max-w-7xl px-6 lg:px-8'
			)}>
				{isOnboardingPage ?
					<>
						<div className="mx-auto max-w-4xl text-center">
							<h2 className="text-base font-semibold leading-7 text-ntpurple">Plans</h2>
							<p className="mt-2 text-4xl font-bold tracking-tight text-bluegray-900 sm:text-5xl">
								Pricing plans for teams of all sizes
							</p>
						</div>
					</>
					: <>
						<div className="mx-auto px-3 text-start">
							<p className="mt-2 text-4xl font-bold tracking-tight text-bluegray-900 sm:text-5xl">
								Plans
							</p>
						</div>
					</>}
				<div className="mx-auto mt-12 grid max-w-md grid-cols-1 gap-y-8 sm:mt-12 lg:mx-0 lg:max-w-none lg:grid-cols-3">
					{tiers.map((tier, tierIdx) => (
						<div
							key={tier.id}
							className={classNames(
								isOnboardingPage ? '' : 'bg-opacity-90',
								// tier.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8',
								// tierIdx === 0 ? 'lg:rounded-r-none' : '',
								// tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : '',
								'flex flex-col justify-between rounded-[30px] bg-white p-8 ring-1 ring-gray-200 mx-[8px]'
							)}
						>
							<div>
								<div className="flex items-center justify-center gap-x-4">
									<h3
										id={tier.id}
										className={classNames(
											// tier.mostPopular ? 'text-ntpurple' : 'text-bluegray-900',
											'text-4xl font-bold leading-8 text-[#4334AD] text-center'
										)}
									>
										{tier.name}
									</h3>
									{/* {tier.mostPopular ? (
										<p className="rounded-full bg-ntpurple/10 px-2.5 py-1 text-xs font-semibold leading-5 text-ntpurple">
											Most popular
										</p>
									) : null} */}
								</div>
								<p className="mt-4 text-sm font-semibold leading-6 text-gray-600 text-center">{tier.description}</p>
								<p className="mt-6 flex items-baseline gap-x-1 justify-center text-center mx-auto">
									<span className="text-3xl font-bold tracking-tight text-bluegray-900">{tier.priceMonthly}</span>
									<span className="text-sm font-semibold leading-6 text-gray-600">/month</span>
								</p>
								<ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
									{tier.features.map((feature) => (
										<li key={feature} className="flex gap-x-3">
											<CheckIcon className="h-6 w-5 flex-none text-ntpurple" aria-hidden="true" />
											{feature}
										</li>
									))}
									{tier.id === 'tier-creator' && <>
										<li key='nofilemap' className="flex gap-x-3">
											<span className="relative group inline-flex">
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-flex text-ntpurple cursor-pointer">
													<path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
												</svg>
												<span className="absolute top-10 scale-0 transition-all min-w-[300px] left-0 rounded-xl bg-gray-800 p-2 text-sm text-white group-hover:scale-100">
													{"Without filemapping, you are responsible for providing youre original file when you download your file certification."}
												</span>
											</span>
											<span>No File Mapping</span>
										</li>
									</>}
								</ul>
							</div>
							{tier.id === 'tier-enterprise' ? (<>
								<button
									// href={tier.href}
									disabled={isOnboardingPage}
									aria-describedby={tier.id}
									onClick={() => handleSubmit(tier.id, tier.price_id)}
									className={classNames(
										// tier.mostPopular
										// 	? 'bg-ntpurple text-white shadow-sm hover:bg-ntpurple-600 cursor-not-allowed opacity-50'
										// 	: 'text-ntpurple ring-1 ring-inset ring-ntpurple hover:bg-ntpurple hover:text-white',
										isOnboardingPage ? 'cursor-not-allowed opacity-50' : 'hover:bg-ntpurple hover:text-white',
										'text-ntpurple ring-1 ring-inset ring-ntpurple',
										'mt-8 rounded-full mx-auto py-4 px-10 text-center text-xl font-bold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ntpurple'
									)}
								>
									{isOnboardingPage ? 'Coming Soon' : 'Contact Us'}
								</button>
							</>) : (<>
								<button
									// href={tier.href}
									aria-describedby={tier.id}
									onClick={() => handleSubmit(tier.id, tier.price_id)}
									// className={classNames(
									// 	tier.mostPopular
									// 		? 'bg-ntpurple text-white shadow-sm hover:bg-ntpurple-600'
									// 		: 'text-ntpurple ring-1 ring-inset ring-ntpurple-200 hover:ring-ntpurple-300',
									// 	'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ntpurple'
									// )}
									className={classNames(
										'flex items-center justify-center bg-ntpurple text-white shadow-sm hover:bg-ntpurple-600',
										'mt-8 rounded-full mx-auto py-4 px-10 text-center text-xl font-bold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ntpurple'
									)}
								>
									{isOnboardingPage ? 'Select plan' : 'Sign Up'}
								</button>

							</>)}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default PlansComponent;
