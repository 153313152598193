import React from 'react';
import logo from '../../assets/home/homepage_gif.gif';
import { useNavigate } from 'react-router-dom';

const FeaturesSection: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div id='neotrust' className="w-full p-8 md:p-20 bg-[#f6f5ff]">
            <div className="container flex flex-wrap items-center mx-auto max-w-7xl">
                <div className="w-full md:w-3/5">
                    <h2 className="text-3xl md:text-5xl font-bold mb-6">Why Neotrust?</h2>
                    <p className="text-xl mb-4">Current solutions lack a digital ownership standard, are complex, and come with a high cost to protect IP.</p>
                    <ul className="list-disc pl-2 md:pl-5 ml-8 text-xl mb-5 md:mb-10 space-y-4">
                        <li><strong>Neotrust</strong> protects your IP for its entire lifecycle by stamping assets in real time.</li>
                        <li><strong>Neotrust</strong> provides the option to copyright, trademark, and patent when you are ready.</li>
                        <li><strong>Neotrust</strong> has preferred pricing for creators and enterprise solutions.</li>
                    </ul>
                    <div className="flex justify-center">
                        <button 
                            onClick={() => navigate('/features')} 
                            className="text-xl mt-4 px-12 py-2.5 text-ntpurple font-bold border-2 border-ntpurple rounded-full transition duration-300 hover:bg-ntpurple hover:text-white focus:outline-none"
                        >
                            Learn More
                        </button>
                    </div>
                </div>
        
                <div className="w-full pt-6 md:w-2/5 md:pt-0 px-4 space-y-6">
                    <img src={logo} alt="" className="w-full h-auto rounded-3xl" />
                </div> 
            </div>
        </div>
    );
}

export default FeaturesSection;
