import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import HomePageComponent from '../pages/home/HomePageComponent';
import AppHeader from '../components/header/AppHeader';
import FAQsPage from '../pages/faqs/FAQsPage';
import Footer from '../components/footer/AppFooter';
import OnBoardingPage from '../pages/onboarding/OnBoardingPage';
import StampComponent from '../pages/stamp/Stamp';
import VerifyComponent from '../pages/verify/Verify';
import Download from '../pages/download/Download';
import PaymentSuccess from '../pages/onboarding/PaymentSuccess';
import LoginChecker from '../pages/auth/LoginChecker';
import PaymentCancelled from '../pages/onboarding/PaymentCancelled';
import DashboardRouter from '../pages/dashboard/DashboardRouter';
import Profile from '../pages/profile/Profile';
import ContactPage from '../pages/contact/ContactPage';
import FeaturesComponent from '../pages/features/FeaturesComponent';
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy';
import TermsAndConditions from '../pages/terms-and-conditions/TermsAndConditions';
import ScrollToTop from '../components/ScrollToTop';
import AnnouncementBar from '../components/AnnouncementBar';
import PlansComponent from '../pages/plans/PlansComponent';
import SolutionsComponent from '../pages/solutions/SolutionsComponent';

const ConditionalHeader: React.FC = () => {
    const location = useLocation();
    const excludedPaths = ['/', '/features', '/solutions', '/plans'];
    if (excludedPaths.includes(location.pathname)) {
        return null;
    } else {
        return <AppHeader />;
    }
};

const AppRouter: React.FC = () => {

    return (
        <Router>
            <ScrollToTop />
            <AnnouncementBar />
            <ConditionalHeader />
            <Routes>
                <Route path="/" element={<HomePageComponent />} />
                <Route path="/faqs" element={<FAQsPage />} />
                <Route path="/onboarding" element={<OnBoardingPage />} />
                <Route path="/dashboard" element={<DashboardRouter />} />
                <Route path="/stamp" element={<StampComponent />} />
                <Route path="/download/:transactionId" element={<Download />} />
                <Route path="/verify" element={<VerifyComponent />} />
                <Route path="/login-checker" element={<LoginChecker />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/features" element={<FeaturesComponent />} />
                <Route path="/solutions" element={<SolutionsComponent />} />
                <Route path="/plans" element={<PlansComponent isOnboardingPage={false} />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/payment/success" element={<PaymentSuccess />} />
                <Route path="/payment/canceled" element={<PaymentCancelled />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default AppRouter;
