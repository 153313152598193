import React, { useState, useCallback, useEffect } from 'react';
import UploadIcon from '../../assets/icons/Upload.svg';
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from '@auth0/auth0-react';
import { TOAST_TYPE, showToastUtil } from '../../components/banner/BannerUtil';
import API from '../../apiClient/NeoTrustAPI';
import { UserStatus } from '../../utils/userUtils';
import { Switch } from '@headlessui/react'
import { STATUS, handleErrorMsg } from '../../utils/common';
import { useNavigate } from 'react-router-dom';
import bglogo from '../../assets/home/uploadBackground.svg';
import iconlogo from '../../assets/icons/timestamp-icon.svg';
import { UserInfoContext } from '../../components/UserInfoProvider';

interface FileMeta {
    fileName: string;
    fileType: string;
    fileSize: number;
    fileLastModified: number;
    fileHash: string;
    fileObjectKey?: string;
    file: File; // Store the actual File object in the FileMeta
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const StampComponent: React.FC = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const api = API.getInstance(useAuth0()).getAxiosInstance(); // Pass auth0Context to API instance
    const [timestampAndStore, setTimestampAndStore] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<FileMeta[]>([]);
    const [loader, setLoader] = useState<STATUS>(STATUS.NONE);
    const navigate = useNavigate();

    const { userStatus, isPremium } = React.useContext(UserInfoContext);

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        const uniqueFiles = acceptedFiles.filter(
            (file) => !selectedFiles.some((selectedFile) => selectedFile.file.name === file.name && selectedFile.file.size === file.size)
        );

        const fileMetaPromises: Promise<FileMeta>[] = uniqueFiles.map((file) => computeFileMetaData(file));

        try {
            setLoader(STATUS.PROCESSING);
            const fileMetaData = await Promise.all(fileMetaPromises);

            const fileUploadPromises: Promise<FileMeta>[] = fileMetaData.map((meta) => {
                if (timestampAndStore) {
                    return uploadFile(meta);
                } else {
                    return Promise.resolve(meta);
                }
            });

            const updatedFileMeta = await Promise.all(fileUploadPromises);

            setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...updatedFileMeta]);
            setLoader(STATUS.SUCCESS);
        } catch (e: any) {
            console.error(e);
            showToastUtil({ status: TOAST_TYPE.ERROR, message: e?.message || 'Failed to upload files.' });
            setLoader(STATUS.ERROR);
        }
    }, [selectedFiles, timestampAndStore]);


    const computeFileMetaData = async (file: File): Promise<FileMeta> => {
        const arrayBuffer = await file.arrayBuffer();
        const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

        return {
            fileName: file.name,
            fileType: file.type,
            fileSize: file.size,
            fileLastModified: file.lastModified,
            fileHash: hashHex,
            fileObjectKey: '',
            file,
        };
    };

    const uploadFile = async (fileMeta: FileMeta): Promise<FileMeta> => {
        const formData = new FormData();
        formData.append('file', fileMeta.file);

        try {
            const { status, data } = await api.post('/upload', formData);
            if (status !== 200) throw new Error('File upload failed.');
            return {
                ...fileMeta,
                fileObjectKey: data.objectKey,
            };
        } catch (e: any) {
            console.error('File upload failed.', e);
            const errMsg = handleErrorMsg(e, 'File upload failed.');
            showToastUtil({ status: TOAST_TYPE.ERROR, message: errMsg });
            throw e;
        }
    };

    const checkUserStatus = () => {
        if (!isAuthenticated) {
            showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Please sign in to your account.' });
            loginWithRedirect();
            return false;
        } else if (isAuthenticated) {
            if (userStatus === UserStatus.NO_USER) {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Please sign up for an account.' });
                navigate('/onboarding');
                return false;
            } else if (userStatus === UserStatus.EXPIRED) {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Your account has expired. Please renew your subscription.' });
                navigate('/onboarding?step=plan');
                return false;
            } else if (userStatus === UserStatus.KEY_READY) {
                showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Please complete your onboarding.' });
                navigate('/onboarding?step=plan');
                return false;
            } else if (userStatus === UserStatus.COMPLETE) {
                return true;
            }
        }
        return false;
    }

    const handleSubmit = async () => {
        if (selectedFiles.length === 0) {
            showToastUtil({ status: TOAST_TYPE.ERROR, message: 'Please select files to stamp.' });
            return
        }
        if (!checkUserStatus()) return;
        const filesPayload = selectedFiles.map(({ file, ...rest }) => rest);
        console.log('Submitting files:', filesPayload);
        try {
            setLoader(STATUS.PROCESSING);
            const { status, data } = await api.post('/transaction/neostamp', {
                metadata: filesPayload,
                timestamp_and_store: timestampAndStore, // Use timestampAndStore state here
            });

            if (status !== 200) throw new Error('Failed to submit files for stamping.');
            console.log('responseData', data);
            showToastUtil({ status: TOAST_TYPE.SUCCESS, message: 'Files submitted for stamping successfully!' });
            setSelectedFiles([]);
            setLoader(STATUS.SUCCESS);
        } catch (e: any) {
            console.error('Failed to submit files for stamping.', e);
            const errMsg = handleErrorMsg(e, 'Failed to submit files for stamping.');
            showToastUtil({ status: TOAST_TYPE.ERROR, message: errMsg });
            setLoader(STATUS.ERROR);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const removeFile = (fileMeta: FileMeta) => {
        setSelectedFiles((prevSelectedFiles) => prevSelectedFiles.filter((prevFile) => prevFile !== fileMeta));
    };

    const handleRemoveClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, fileMeta: FileMeta) => {
        event.stopPropagation();
        removeFile(fileMeta);
    };

    return (
        <div className="dark page-container justify-center bg-offwhite">
            <div className="container rounded-3xl m-6 p-6 px-6 mx-auto relative bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${bglogo})` }}
            >
                <div className="flex flex-wrap justify-center dark:text-white">
                    <img className="w-20 h-auto -my-3" src={iconlogo} alt="Timestamp" />
                    <div className="w-full p-4 flex flex-col items-center justify-center text-4xl font-bold text-center">Timestamp your files</div>
                    <div className="w-full p-4 flex flex-col items-center justify-center">

                        <div {...getRootProps()} className={`w-full p-8 cursor-pointer border-dashed border-4 border-slate-300 rounded-2xl ${isDragActive ? 'active' : ''}`}>
                            <input {...getInputProps()} />
                            <div className="flex flex-col items-center justify-center mb-6">
                                <img className="w-20" src={UploadIcon} alt="Upload" />
                                <p className="leading-loose text-gray-500 dark:text-gray-200 text-xl text-center">
                                    {isDragActive ? 'Drop the files here ...' : <>
                                        <span>Drag and drop your files here or<br /></span>
                                        <span className="underline">click</span>
                                        <span> to select files</span>
                                    </>}
                                </p>
                            </div>
                            <ul>
                                {selectedFiles.map((file) => (
                                    <li key={file.file.name} className="flex items-center justify-between p-2 my-1 bg-[#2b2f42]">
                                        <span>{file.file.name}</span>
                                        <button
                                            onClick={(event) => handleRemoveClick(event, file)}
                                            className="text-red-500 dark:text-red-400 font-bold focus:outline-none"
                                        >
                                            Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {isPremium && (
                            <div className="flex items-center justify-center mt-6 mb-3">
                                <Switch.Group as="div" className="flex items-center justify-between">
                                    <Switch
                                        checked={timestampAndStore}
                                        onChange={(checked) => {
                                            setTimestampAndStore(checked);
                                            setSelectedFiles([]);
                                        }}
                                        className={classNames(
                                            timestampAndStore ? 'bg-ntpurple' : 'bg-gray-400',
                                            'mr-4 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-ntpurple focus:ring-offset-2'
                                        )}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                timestampAndStore ? 'translate-x-5' : 'translate-x-0',
                                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                            )}
                                        />
                                    </Switch>
                                    <span className="flex flex-grow flex-col">
                                        <Switch.Label as="span" className="text-sm font-medium leading-6" passive>
                                            Check here to store your files.
                                        </Switch.Label>
                                        {/* <Switch.Description as="span" className="text-sm text-gray-500">
                                            This option allows you to both timestamp and store your files securely. Files are only stored securely for 10 days.
                                        </Switch.Description> */}
                                    </span>
                                </Switch.Group>
                            </div>
                        )}
                        <div className="flex flex-row items-center justify-center mt-6">
                            <button
                                type="button"
                                onClick={() => handleSubmit()}
                                className="mx-2 flex py-2 px-6 bg-ntpurple hover:bg-ntpurple-600 text-white sw-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-full"
                            >
                                Stamp Now
                            </button>
                        </div>
                    </div>
                </div>
                {(loader === STATUS.PROCESSING) && (
                    <div className="absolute inset-0 flex items-center justify-center z-10 rounded-3xl bg-opacity-50 bg-bluegray-900">
                        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-ntpurple"></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StampComponent;
