import React from 'react';
import { Auth0ProviderComponent } from './auth/Auth0ProviderComponent';

import AppRouter from './router/AppRouter';
import { ToastContainer } from 'react-toastify';
import { EnvConfig } from './utils/envConfig';
import { UserInfoProvider } from './components/UserInfoProvider';
import { ConfigProvider } from 'antd';

const App: React.FC = () => {
	{/* use only app level higher-order components or providers here. */ }
	return (
		<Auth0ProviderComponent
			domain={`${EnvConfig.AUTH0_DOMAIN}`}
			clientId={`${EnvConfig.AUTH0_CLIENTID}`}
			authorizationParams={{
				redirect_uri: `${window.location.origin}/login-checker`,
				audience: `${EnvConfig.API_DOMAIN}/`,
				// audience: 'https://localhost:3000',
				scope: 'openid profile email',
			}}
		>
			{/* <OtherProvider> */}
			<UserInfoProvider>
				<ConfigProvider theme={{
					token: {
						fontFamily: 'Red Hat Display Variable',
					},
				}}>
					<AppRouter />
					<ToastContainer position="top-right" newestOnTop={true} />
				</ConfigProvider>
			</UserInfoProvider>
			{/* </OtherProvider> */}
		</Auth0ProviderComponent>
	);
}

export default App;
