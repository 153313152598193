import React from 'react';
import timestampIcon from '../../assets/icons/History.svg';
import verifyIcon from '../../assets/icons/Verified.svg';
import identityIcon from '../../assets/icons/Profile.svg';
import bgLogo from '../../assets/home/homepage-background.svg';
import AppHeader from '../../components/header/AppHeader';

const Solutions = [
    {
        title: "Timestamp",
        description: "Securely authenticate your work with a single click. Neotrust's patented process provides definitive ownership, instant timestamping, and identity verification ensuring data protection from inception to completion.",
        classes: 'w-full p-8 border-b md:w-1/2 md:border-r lg:w-1/3 border-bluegray',
        icon: <img className="w-10 h-auto" src={timestampIcon} alt="" />
    },
    {
        title: "Verify",
        description: "Tie your work to your identity, and clarify and certify ownership. Neotrust provides instant verification and timestamps for all your work. We also offer enterprise solutions for immutable tracking and traceability of inventory.",
        classes: 'w-full p-8 border-b md:w-1/2 lg:w-1/3 lg:border-r border-bluegray',
        icon: <img className="w-10 h-auto" src={verifyIcon} alt="" />
    },
    {
        title: "Identity",
        description: "You are now permanently linked to your work. Neotrust has created your digital identity which defines what you created, when it was created, and verifies your identity and ownership.",
        classes: 'w-full p-8 border-b md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0 border-bluegray',
        icon: <img className="w-10 h-auto" src={identityIcon} alt="" />

    },
];

const SolutionsComponent: React.FC = () => {
    return (
        <div className="w-full mx-0 min-h-screen bg-center bg-no-repeat bg-cover flex flex-col" style={{ backgroundImage: `url(${bgLogo})` }}>
            <AppHeader classNameProps="bg-opacity-50" />
            <div className="container p-6 px-6 mx-auto mb-6">
                <div className="sm:px-6 text-start">
                    <h2 className="text-4xl font-bold tracking-wide text-[#050426]">
                        Solutions
                    </h2>
                </div>
                <div className="flex flex-wrap my-8 pb-6 text-white bg-bluegray rounded-3xl">
                    <div className="flex w-full items-center justify-center pt-6">
                        <div className='text-center text-4xl flex-wrap'>
                            <span className=''>The only global&nbsp;</span>
                            <span className='font-bold'>patented process</span>
                        </div>
                    </div>
                    {Solutions.map((feature, index) => (
                        <div key={index} className={`${feature.classes}`}>
                            <div className="flex items-center text-center justify-center mb-6">
                                <span className='inline-flex text-center items-center px-6 py-2 mx-6 bg-[#d9d2ff] rounded-full text-ntpurple font-semibold w-full justify-center'>
                                    {feature.icon}
                                    <div className="ml-2 text-xl font-bold">
                                        {feature.title}
                                    </div>
                                </span>
                            </div>
                            <p className="leading-loose text-gray-200 text-md text-center">
                                {feature.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SolutionsComponent;
