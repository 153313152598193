import React from 'react';
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";

interface Auth0ProviderComponentProps extends Auth0ProviderOptions {
    children: React.ReactNode;
}

export const Auth0ProviderComponent: React.FC<Auth0ProviderComponentProps> = (props) => {
    const { children, ...auth0Props } = props;

    return (
        <Auth0Provider {...auth0Props}>
            {children}
        </Auth0Provider>
    );
}
