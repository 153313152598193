import React from 'react';
// import { Routes, Route, Link, Navigate } from 'react-router-dom';
// import DashboardNavMenu from './DashboardNavMenu';
import TransactionsTable from './TransactionsTable';
import UsageCharts from './UsageCharts';

const DashboardRouter: React.FC = () => {
    return (
        <div className="page-container bg-offwhite">
            {/* <div className='w-full text-3xl font-bold px-10 pt-6 text-[#050426]'>{'Your Dashboard'}</div> */}
            <div className="flex flex-col">
                <UsageCharts />
                <TransactionsTable />
                {/* <DashboardNavMenu /> */}
                {/* <Routes>
                    <Route path="/transactions" element={<TransactionsTable />} />
                    <Route path="/usage" element={<UsageCharts />} />
                    <Route path="*" element={<Navigate to="transactions" />} />
                </Routes> */}
            </div>
        </div>
    );
}

export default DashboardRouter;
